/* You can add global styles to this file, and also import other style files */

@import url("assets/css/custom.css");
@import "~@ng-select/ng-select/themes/default.theme.css";
html,
body {
    height: 100%;
}

body {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin: 0;
}

th.check-all {
    width: 3em;
    padding-left: .65em;
}

div.sidebar .row {
    min-height: 10em;
    border: 1px solid #656565;
    margin: 1.5em 0;
}

th.link,
.clickable {
    cursor: pointer;
}

app-filter {
    width: 100% !important;
}

tr.highlighted,
mat-row.highlighted {
    background-color: #757575 !important;
    color: white;
}

tr.highlighted td,
mat-row.highlighted mat-cell {
    color: white;
}

.table-controls {
    background-color: #f3f3f3;
    border: 1px solid #efefef;
    border-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}

.table-controls ul {
    padding: 0px;
    margin: 0 -15px;
}

.table-controls ul li {
    display: inline-block;
    font-size: 13px;
    border-right: 1px solid #E8E8E8;
    padding: 10px;
    margin-left: -4px;
}

.loading-indicator {
    width: 100%;
    height: 100%;
    text-align: center;
}

button.mat-menu-item.active {
    background-color: grey;
}

.mat-form-field {
    font-size: 14px;
}

div.top-margin {
    margin-top: 2em;
}

.mat-card-with-toolbar mat-card {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.mat-full-width {
    width: 100%;
}

a:hover,
a:focus,
a:active,
a:visited {
    font-weight: bolder;
}

a.disabled {
    opacity: 0.5;
    color: gray;
    cursor: not-allowed;
    pointer-events: none;
}

a.mat-tab-link {
    text-decoration: none;
}

a.mat-tab-link .mat-icon {
    margin-right: 0.2em;
}

footer a,
.highlighted a {
    color: #D1C4E9 !important;
}

div.header-row {
    margin-top: 1em;
}

.inside-header {
    margin-left: 0;
    margin-right: 0;
}

.inside-header .col-xs-12 {
    padding-right: 0;
    padding-left: 0;
}

.inside-header .mat-full-width {
    width: 100%;
}

.mat-full-width {
    width: 100%;
}

@media only screen and (min-width: 992px) {
    .inside-header .mat-full-width {
        width: 90%;
    }
}