a {
	color: #673AB7;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: 0.5rem;
	font-family: inherit;
	font-weight: 400;
	line-height: 1.2;
	color: inherit;
}

h1,
.h1 {
	font-size: 2.5rem;
}

h2,
.h2 {
	font-size: 2rem;
}

h3,
.h3 {
	font-size: 1.75rem;
}

h4,
.h4 {
	font-size: 1.5rem;
}

h5,
.h5 {
	font-size: 1.25rem;
}

h6,
.h6 {
	font-size: 1rem;
}

label {
	display: inline-block;
	margin-bottom: .5rem;
	font-weight: normal;
}